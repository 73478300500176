import React from 'react'
import { FaTwitter, FaInstagram, FaYoutube } from 'react-icons/fa';
import './footer.css'
import logo from '../../Images/logo.png';


const Footer = () => {
  return (
    <div className='footer ' id='Contact'>
      <div className='footer__container'>
        <div className='footer__logo smooth-scroll'>
          <a href='#Home'>
            <img src={logo} alt="logo" />
          </a>
        </div>
        <div className='footer__social'>
          <p>Social Media</p>
          <div className='footer__icons'>
            <a href="https://www.instagram.com/9saminj" target="_blank" rel="noopener noreferrer" className='social_cont'>
              <FaInstagram size={39} className='social'/>
            </a>
            <a href="https://www.Youtube.com" target="_blank" rel="noopener noreferrer" className='social_cont'>
              <FaYoutube size={39} className='social'/>
            </a>
            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" className='social_cont'>
              <FaTwitter size={39} className='social'/>
            </a>
          </div>
        </div>
        <div className='footer__contacts'>
          <div><p>The Netherlands, Amersfoort</p></div>
          <div><p>9saminj@gmail.com</p></div>
          <div><p>+31 684794708</p></div>
        </div>
        <div></div>
        <div className='copyright'><p>© 2023 Samin Jahani | All rights reserved.</p></div>
        <div></div>
      </div>
    </div>
  )
}

export default Footer