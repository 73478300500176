import React from 'react';
import './App.css';
import {Footer, Header, Main, Navbar,Video} from './containers';


const App = () => {
  return (
    <div>
      <Header />
      <Navbar />
      <Main />
      <Video />
      <Footer />
    </div>
  )
}

export default App;
