import React from 'react'
import Mainlayout from '../layout/Mainlayout'
import cat from '../Images/Cat.png'
import './pages.css'
import { Link } from 'react-router-dom'

const Cat = () => {
  return (
    <div>
          <Mainlayout>
            <div className='pages section__padding'>
              <div className='pages__photo'>
                <img src={cat} alt="cat" />
              </div>
              <div className='photo__caption'>
                <h1 className='gradient__text2'>Cat</h1>
                <p>2023</p>
                <p>29,7 X 42 Cm</p>
                <p>Graphite Pencil On Paper</p>
                <p>Order Your Prints <Link to="/Prints" style={{textDecoration : 'Underline', color: 'lightblue'}}>HERE</Link></p>
              </div>
            </div>
          </Mainlayout>
    </div>
  )
}

export default Cat