import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Mainlayout from '../layout/Mainlayout'
import thor from '../Images/Thor.png'
import './pages.css'
import { Link } from 'react-router-dom'

const Thor = () => {
  const location = useLocation();

  useEffect(() => {
    // Check for scrollTo parameter in the URL
    const params = new URLSearchParams(location.search);
    const scrollTo = params.get('scrollTo');

    if (scrollTo) {
      const element = document.getElementById(scrollTo);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      // If no scrollTo parameter, scroll to the top of the page
      window.scrollTo(0, 0);
    }
  }, [location.search]);
  return (
    <div>
          <Mainlayout>
            <div className='pages section__padding'>
              <div className='pages__photo'>
                <img src={thor} alt="thor" />
              </div>
              <div className='photo__caption'>
                <h1 className='gradient__text2'>Thor</h1>
                <p>2023</p>
                <p>29,7 X 42 Cm</p>
                <p>Graphite Pencil On Paper</p>
                <p>Order Your Prints <Link to="/Prints" style={{textDecoration : 'Underline', color: 'lightblue'}}>HERE</Link></p>
              </div>
            </div>
          </Mainlayout>
    </div>
  )
}

export default Thor