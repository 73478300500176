import React from 'react'
import Mainlayout from '../layout/Mainlayout'
import walter from '../Images/Walter.png'
import './pages.css'
import { Link } from 'react-router-dom'

const WalterWhite = () => {
  return (
    <div>
          <Mainlayout>
            <div className='pages section__padding'>
              <div className='pages__photo'>
                <img src={walter} alt="Walter White" />
              </div>
              <div className='photo__caption'>
                <h1 className='gradient__text2'>Walter White</h1>
                <p>2023</p>
                <p>21 X 29,7 Cm</p>
                <p>Graphite Pencil On Paper</p>
                <p>Order Your Prints <Link to="/Prints" style={{textDecoration : 'Underline', color: 'lightblue'}}>HERE</Link></p>
              </div>
            </div>
          </Mainlayout>
    </div>
  )
}

export default WalterWhite