import React, {useState} from 'react'
import './navbarsec.css'
import {RiMenu3Line, RiCloseLine} from 'react-icons/ri'
import logo from '../../Images/logo.png';
import { Link } from 'react-router-dom';

const Menu = () => (
    <>
    <p><Link to='/Home'>Home</Link></p>
    <p><Link to='/Home'>Videos</Link></p>
    <p><Link to='/Prints'>Prints</Link></p>
    <p><a href='#About'>About</a></p>
    <p><a href='#Contact'>Contact</a></p>
    </>
)

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className='navbarsec section__padding smooth-scroll' id='Home'>
            <div className='navbar__linkssec'>
                <div className='navbar__links-logosec'>
                    <Link to="/Home"><img src={logo} alt="" /></Link>
                </div>
                <div className='navbar__links-containersec'>
                    <Menu />
                </div>
                </div>
                <div className="gpt3__navbar-menu">
                {toggleMenu
                ? <RiCloseLine color='#fff' size={27} onClick={() => setToggleMenu(false)} />
                : <RiMenu3Line color='#fff' size={27} onClick={() => setToggleMenu(true)} />
                }
                {toggleMenu && (
                <div className="gpt3__navbar-menu_container scale-up-center">
                    <div className="gpt3__navbar-menu_container-links">
                    <Menu />
                    </div>
                </div>
                )}
            </div>
    </div>
  )
}

export default Navbar