import React, { useState, useRef } from 'react';
import Video1 from '../../Images/Video1.mp4'
import {BsFillPlayFill, BsPauseFill } from 'react-icons/bs';
import './video.css'

const Video = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const handleTogglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div className='app__video-cont' id='Videos'>
      <div className='app__video'>
        <video
        src={Video1}
        ref={videoRef}
        type="Video/mp4"
        loop
        controls={false}
        />
        <div className='video__overlay'>
        <div className='video__icons' onClick={handleTogglePlay}>
        {isPlaying ? (
            <BsPauseFill color="#fff" fontSize={30} />)
            : (<BsFillPlayFill color="#fff" fontSize={30} />)}
        </div>
        </div>

    </div>
    </div>

  )
}

export default Video