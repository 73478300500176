import React from 'react';
import './header.css';
import image from '../../Images/bg.jpg'


const Header = () => {
  return (
    <div className='app__bg' id='Home'>
      <div className='overlay'>
        <img src={image} alt="background"/>
      </div>
    </div>
  )
}

export default Header