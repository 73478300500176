import React from 'react'
import './main.css'
import goggins from '../../Images/Goggins.png';
import thor from '../../Images/Thor.png';
import walter from '../../Images/Walter.png';
import shahin from '../../Images/Shahin.png';
import thomas from '../../Images/Thomas.png';
import cat from '../../Images/Cat.png';
import { Link } from 'react-router-dom';

const main = () => {
  return (
    <div className='main'>
      <div className='main__bg'>
        <Link to='/DavidGoggins'>
          <div className='main__container'>
            <p>David Goggins</p>
            <img src={goggins} alt="goggins" />
          </div>
        </Link>
        <Link to='/Thor'>
          <div className='main__container'>
            <p>Thor</p>
            <img src={thor} alt="thor" />
          </div>
        </Link>
        <Link to='/WalterWhite'>
          <div className='main__container'>
            <p>Walter White</p>
            <img src={walter} alt="walter" />
          </div>
        </Link>
        <Link to='/Cat'>
          <div className='main__container'>
            <p>Cat</p>
            <img src={cat} alt="cat" />
          </div>
        </Link>
        <Link to='/ShahinNajafi'>
          <div className='main__container'>
            <p>Shahin Najafi</p>
            <img src={shahin} alt="shahin" />
          </div>
        </Link>
        <Link to='/ThomasShelby'>
          <div className='main__container'>
            <p>Thomas Shelby</p>
            <img src={thomas} alt="thomas" />
          </div>    
        </Link>
      </div>
    </div>
  )
}

export default main