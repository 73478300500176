import React, {useState} from 'react'
import './navbar.css'
import {RiMenu3Line, RiCloseLine} from 'react-icons/ri'
import logo from '../../Images/logo.png';
import { Link } from 'react-router-dom';

const Menu = () => (
    <>
    <p><Link to='/Home'>Home</Link></p>
    <p><a href='#Videos'>Videos</a></p>
    <p><Link to='/Prints'>Prints</Link></p>
    <p><a href='#About'>About</a></p>
    <p><a href='#Contact'>Contact</a></p>
    </>
)

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className='navbar section__padding'>
        <div className='navbar__links'>
            <div className='navbar__links-logo'>
                <Link to="/Home"><img src={logo} alt="" /></Link>
            </div>
            <div className='navbar__links-container'>
                <Menu />
            </div>
        </div>
        <div className="gpt3__navbar-menu">
            {toggleMenu
            ? <RiCloseLine color='#fff' size={27} onClick={() => setToggleMenu(false)} />
            : <RiMenu3Line color='#fff' size={27} onClick={() => setToggleMenu(true)} />
            }
            {toggleMenu && (
            <div className="gpt3__navbar-menu_container scale-up-center">
                <div className="gpt3__navbar-menu_container-links">
                <Menu />
                </div>
            </div>
            )}
        </div>
        <div className='navbar__text'>
            <h1 className='gradient__text'>Capturing Realism: Portraits by Samin Jahani</h1>
        </div>
    </div>
  )
}

export default Navbar