import React from 'react'
import Mainlayout from '../layout/Mainlayout'
import './prints.css'


const Prints = () => {
  return (
    <Mainlayout>
        <div className='printss'>
          <div className='prints gradient__text'><p>This page will be availible soon</p></div>
        </div>
    </Mainlayout>
  )
}

export default Prints