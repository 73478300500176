import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Prints from "./Pages/Prints";
import David from "./Pages/David";
import Thor from "./Pages/Thor";
import WalterWhite from './Pages/WalterWhite';
import Cat from './Pages/Cat';
import ShahinNajafi from './Pages/ShahinNajafi';
import ThomasShelby from './Pages/ThomasShelby';



import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";


const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
  },
  {
    path: "Home",
    element: <App/>,
  },
  {
    path: "Prints",
    element: <Prints/>,
  },
  {
    path: "DavidGoggins",
    element: <David/>,
  },
  {
    path: "Thor",
    element: <Thor/>,
  },
  {
    path: "WalterWhite",
    element: <WalterWhite/>,
  },
  {
    path: "Cat",
    element: <Cat/>,
  },
  {
    path: "ShahinNajafi",
    element: <ShahinNajafi/>,
  },
  {
    path: "ThomasShelby",
    element: <ThomasShelby/>,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<RouterProvider router={router} />
);

