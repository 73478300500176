import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {Footer} from '../containers'
import { Navbarsec } from '../containers'
import image from '../Images/bg.jpg'

const Mainlayout = ({children}) => {
  const location = useLocation();

  useEffect(() => {
    // Check for scrollTo parameter in the URL
    const params = new URLSearchParams(location.search);
    const scrollTo = params.get('scrollTo');

    if (scrollTo) {
      const element = document.getElementById(scrollTo);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      // If no scrollTo parameter, scroll to the top of the page
      window.scrollTo(0, 0);
    }
  }, [location.search]);


  return (
    <div>
      <div className='overlay2 '>
        <img src={image} alt="background"/>
      </div>
      <Navbarsec />
      <div>{children}</div>
      <Footer />
    </div>
  )
}

export default Mainlayout